<template>
	<el-form
		ref="itemForm"
		class="option-item-container"
		:model="formData"
		:rules="rules"
	>
		<el-form-item
			:label="itemIndex == 0 ? 'Товар' : ''"
			prop="product_id"
			class="mcol-xs-6 ml-auto"
		>
			<SimpleSpinner :active="productsLoading" />
			<el-select
				v-model="formData.product_id"
				placeholder="Выберите товар"
				filterable
			>
				<el-option
					v-for="item in productsList"
					:key="'product_id-' + item.id"
					:label="`${item.title_ru} - ( ${item.new_price || item.price}₪ )`"
					:value="item.id"
				/>
			</el-select>
		</el-form-item>

		<el-form-item
			:label="itemIndex == 0 ? 'Количество' : ''"
			prop="quantity"
			class="mcol-xs-3"
		>
			<el-input-number
				v-model="formData.quantity"
				placeholder="введите значение"
				:min="1"
			/>
		</el-form-item>

		<div class="el-form-item mcol-xs-1 info-form-item">
			<label v-if="itemIndex == 0" class="el-form-item__label">Сумма</label>
			<div class="el-form-item__content">
				<div v-text="`${total_amount}₪`"></div>
			</div>
		</div>

		<el-button
			class="action-button"
			size="mini"
			type="danger"
			icon="icomoon icon-cross"
			@click="removeItem"
		/>
	</el-form>
</template>

<script>
import { updateFormData, findItemBy /* , prepareSubmitData */ } from '@/helpers';
import { required } from '@/constants/validation';

export default {
	props: {
		itemIndex: Number,
		// clearable: Boolean,
		// query: String,
		itemData: {
			type: Object,
			required: true
		},

		formDataProp: String,
		productsList: {
			type: Array,
			default: () => []
		},
		productsLoading: Boolean
	},

	data() {
		return {
			new: false,
			itemId: null,

			total_amount: 0,
			selectedProduct: null,

			formData: {
				id: null,
				product_id: null,
				quantity: 1
			}
		};
	},

	computed: {
		rules: () => ({
			product_id: required
		}),

		itemFormData() {
			return this.itemData.data;
		}
	},

	created() {
		this.setupPage(this.itemData);
		// console.log('created')
		// this.searchQuery = this.query ? this.query : '';
	},

	methods: {	

		calcAmount({ new_price, price }, quantity) {
			const total_amount = (new_price || price) * quantity;
			if (total_amount) {
				this.total_amount = total_amount
				// console.log('calc')
				this.$emit('recalc_amount');
			}
		},

		setupPage(item) {
			this.new = item.new || false;
			this.itemId = item.id;
			this.formData = this.setupForm(item.data, this.formData);
			this.selectedProduct = this.itemFormData;
			// this.calcAmount(this.itemFormData, this.formData.quantity);
		},

		setupForm(itemData, formData) {
			return updateFormData(itemData, formData);
		},

		validateItemForm() {
			this.$refs['itemForm'].validate(valid => {
				let payload = {
					data: this.formData,
					formDataProp: this.formDataProp || 'orderProducts',
					isValid: valid
				};

				if (!payload.data.id) delete payload.data.id;

				// console.log(payload);
				this.$emit('ready', payload);
			});
		},

		removeItem() {
			// this.$emit('onRemove', this.itemId);
			this.$emit('onRemove', {
				id: this.itemId,
				listName: 'orderProductsItemsList'
			});
		}
	},

	watch: {
		'formData.product_id'(id) {
			const product = findItemBy('id', id, this.productsList);
			if (product) {
				this.selectedProduct = product;
			}
		},

		selectedProduct(product) {
			if (product) {
				this.calcAmount(product, this.formData.quantity);				
			}
		},

		'formData.quantity'(quantity) {
			if (this.selectedProduct) {
				this.calcAmount(this.selectedProduct, quantity);				
			}
		}
	},
};
</script>
